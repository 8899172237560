import styled, { css } from 'styled-components';
import BaseText from '@deprecated-ui/common/Text';
import Flex from '@deprecated-ui/common/Flex';
import device from '@deprecated-ui/_style/device';
import color from '@deprecated-ui/_style/color';

interface TitleProps {
  small?: boolean;
}

const Title = styled(BaseText).attrs<TitleProps>((props) => ({
  as: props.small ? 'h2' : 'h1',
  family: 'bold',
  height: 1,
  m: 0,
}))`
  font-size: 44px;
  margin-bottom: 45px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 6.5vh;
    margin-bottom: 15px;
    text-align: left;
  }

  ${(props) =>
    // @ts-expect-error
    props.small &&
    css`
      font-size: 32px;
      margin-bottom: 50px;

      @media ${device.laptop} {
        font-size: 44px;
        text-align: center;
      }
    `};
`;

const Text = styled(BaseText).attrs({
  family: 'light',
  height: 1.5,
})`
  font-size: 17px;
  margin: auto;
  text-align: center;

  @media ${device.laptop} {
    font-size: 19px;
    margin: unset;
    text-align: left;
  }
`;

const PriceDesc = styled(BaseText)`
  font-size: 16px;
  text-align: center;
  width: 100%;

  @media ${device.laptop} {
    font-size: 20px;
    width: 100%;
  }

  b {
    font-family: var(--bold-font);
  }
`;

const ContentWrapper = styled(Flex)`
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 100px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > .small,
  & > .medium,
  & > .regular,
  & > .big {
    width: 100%;
  }

  @media ${device.laptop} {
    flex-direction: row;

    div:first-child {
      padding-right: 50px;
    }
    div:last-child {
      padding-left: 50px;
    }

    & > div.regular {
      width: 60%;
    }

    & > div.big {
      width: 70%;
    }

    & > div.medium {
      width: 50%;
    }

    & > div.small {
      width: 40%;
    }
  }
`;

const PriceWrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
})`
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;

  img {
    height: 50px;

    &.left {
      float: left;
      margin-top: -50px;
    }

    &.right {
      float: right;
      margin-bottom: -50px;
    }
  }

  @media ${device.tablet} {
    img {
      height: auto;
    }
  }

  @media ${device.laptopL} {
    img {
      &.right {
        margin-bottom: -75px;
      }
    }
  }
`;

const PriceRate = styled(Text).attrs({
  family: 'bold',
  color: color.primary,
})`
  font-size: 40px;

  @media ${device.tablet} {
    font-size: 60px;
  }

  @media ${device.laptopL} {
    font-size: 80px;
  }
`;

const PriceLabel = styled(Text)`
  font-size: 20px;

  @media ${device.laptop} {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const ImageWrapper = styled(Flex)`
  justify-content: center;

  @media ${device.laptop} {
    justify-content: flex-start;

    img {
      max-width: 100%;
    }
  }
`;

export {
  Title,
  Text,
  PriceDesc,
  ContentWrapper,
  PriceWrapper,
  PriceRate,
  PriceLabel,
  ImageWrapper,
};
